import { useState, useEffect } from 'preact/hooks'

export type CounterDisplayProps = {
    startNumber: number;
    targetNumber: number;
    interval: number; 
}


export const CounterDisplay = ({
    startNumber,
    targetNumber,
    interval
}: CounterDisplayProps) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        setCount(startNumber || 0) 
            const timer = setInterval(() => {
                setCount((prevCount) => {
                const nextCount = prevCount + 1;
                if (nextCount > targetNumber) {
                    clearInterval(timer);
                    return targetNumber;
                }
                return nextCount;
            });
        }, interval);
  
        return () => clearInterval(timer);
    }, [targetNumber, interval, startNumber]);
  
    return (
        <>{count?.toLocaleString()}</>
    );
}