import {h} from 'preact';
import register from 'preact-custom-element';
import { CounterDisplay, type CounterDisplayProps } from '@/components/CounterDisplay';

const renderCounterDisplay = ({ startNumber, targetNumber, interval }: CounterDisplayProps) => {
    return h(CounterDisplay, {
        startNumber: parseInt(`${startNumber}`),
        targetNumber: parseInt(`${targetNumber}`),
        interval 
    })
}

register(
    renderCounterDisplay,
    'counter-display',
    ['start-number', 'target-number', 'interval']
);
